import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import styles from "assets/jss/material-kit-react/views/projectsPage";

// Sections for this page
import ContactSection from "components/ContactSection/ContactSection";
import ProjectSection from "views/ProjectsPage/Sections/ProjectSection";
// @ts-ignore
import voorEnNaProject from "views/ProjectsPage/Projects/VoorEnNaProject";
import {blackColor} from "assets/jss/material-kit-react";
import {track} from "util/useTracking";
import GridContainer from "components/Grid/GridContainer";
import gfsProject from "views/ProjectsPage/Projects/GfsProject";
import langeProject from "views/ProjectsPage/Projects/LangeProject";
import casalingoProject from "views/ProjectsPage/Projects/CasalingoProject";
import kast1Project from "views/ProjectsPage/Projects/Kast1Project";
import dcmProject from "views/ProjectsPage/Projects/DcmProject";
import randomProject from "views/ProjectsPage/Projects/RandomProject";
import corsoProject from "views/ProjectsPage/Projects/CorsoProject";

// @ts-ignore
import achtergrond from "assets/img/boot.jpg";


// @ts-ignore
const useStyles = makeStyles(styles);

export default function ProjectPage(props) {

    const classes = useStyles();
    const {...rest} = props;


    const projects = [
        voorEnNaProject,
        gfsProject,
        langeProject,
        casalingoProject,
        kast1Project,
        dcmProject,
        randomProject,
        corsoProject
    ]

    track("Project Page");

    return (
        <div  className={classes.main}>
            <Header
                fixed
                {...rest}
            />
            <div className={classNames(classes.main)}>
                <div className={classes.container}>
                    <div className={classes.section}>
                        <GridContainer>
                            {projects.map(project =>
                                <ProjectSection key={project.title}
                                                images={project.images} thumbnails={project.thumbnails}/>
                            )}

                        </GridContainer>
                    </div>
                </div>
                <ContactSection/>
            </div>
            <Footer color={blackColor} whiteFont/>
        </div>
    );
}

import {cardTitle, darkBlueColor, title} from "../../../material-kit-react";
import imagesStyle from "../../../material-kit-react/imagesStyles.js";


const contactSectionStyle = {
  section: {
    textAlign: "center",
    color: darkBlueColor,
    paddingLeft: "25px",
    marginBottom: "50pt"
  },
  description: {
    fontSize: "16pt",
    textAlign: "left"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  },
  p: {
    fontSize: "14pt",
    lineHeight: "2em",
    fontWeight: "500"
  },
  a: {
    color: darkBlueColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  directionsLink: {
    fontWeight: "600"
  },
  routeDiv: {
    justifyContent: "center !important",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    fontSize: "14pt",
    flexWrap: "wrap"
  },
  routeText: {
    fontSize: "14pt",
    "@media (min-width: 434px)": {
      paddingLeft: "1em"
    }
  }
};

export default contactSectionStyle;

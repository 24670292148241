import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

// @material-ui/icons
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import FactoryIcon from '@mui/icons-material/Factory';
import EngineeringIcon from '@mui/icons-material/Engineering';
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea";

import styles from "assets/jss/material-kit-react/views/sections/productStyle";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function ProductSection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <Link to="/services?service=Kasten">
                            <InfoArea
                                title="Bordenbouw"
                                description={(
                                    <span className={"left"}>Voor elke opdracht op het vlak van industriële of residentiële bordenbouw bieden we een gepaste oplossing. Hierbij streven we altijd naar een optimaal evenwicht tussen prijs, kwaliteit, praktische haalbaarheid en service.
                                    <br/>
                                    <br/>
                                    Onze borden worden gebouwd door techniekers die ervaring hebben in het veld, waardoor we graag meedenken met u als klant en steeds met een praktische blik kijken naar de voorgestelde plannen. Zijn er nog geen plannen? Dan helpen wij u ook daarmee graag verder.
                                    <br/>
                                    <br/>
                                    Door deze aanpak blinken we uit in het maatwerk van onder andere:
                                    <ul className={"dashed"}>
                                        <li> Stuurborden (PLC, relais, …)</li>
                                        <li> Vermogenborden</li>
                                        <li> Instrumentatie- en meetborden</li>
                                        <li> Klemmenkasten</li>
                                        <li> Klassieke (residentiële) verdeelborden</li>
                                    </ul>
                                </span>)}
                                icon={ElectricBoltIcon}
                                iconColor="warning"
                                vertical
                            />
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Link to="/services?service=Industriele_installaties">
                            <InfoArea
                                title="Industriële installaties"
                                description={(
                                    <span>Onze industriële installatiewerken bestaan uit het ontwerpen, plaatsen en onderhouden industriële elektrotechnische installaties en -machines. Ook hier maken we graag het verschil door praktisch mee te denken met de klant. We zorgen hierbij voor een veilige installatie & optimale service van onder andere:
                                    <ul className={"dashed"}>
                                        <li> Laag- en middenspanning</li>
                                        <li> Algemene elektrische voorzieningen</li>
                                        <li> Sensoren en instrumentatie</li>
                                        <li> Bedieningsstations</li>
                                        <li> Geautomatiseerde systemen (zoals PLC’s)</li>
                                        <li> Aansturingen van actoren en motoren</li>
                                        <li> Proces- en/of machineveiligheid</li>
                                        <li> Foutzoeken</li>
                                        <li> Verlichting en domotica</li>
                                        <li> Nazicht voor- of na keuring van de installatie</li>
                                        <li> …</li>
                                    </ul>
                                    </span>)}
                                icon={FactoryIcon}
                                iconColor="gray"
                                vertical
                            />
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Link to="/services?service=Engineering">
                            <InfoArea
                                title="Engineering"
                                description={(
                                    <span>Naast elektrische installatiewerken heeft Van Hoppe BV ook de capaciteiten om nieuwe of te wijzigen installaties te ontwerpen en projecten van A tot Z op te volgen. Hierbij houden we altijd de geldende regelgeving, klant specifieke standaarden en timing nauwlettend in het oog.
                                        <br/>
                                        <br/>
                                        De mogelijkheden:
                                        <ul className={"dashed"}>
                                            <li> E&I Engineering</li>
                                            <li> Functional Safety Engineering (TÜV Rheinland, #18428/ 19, Safety Instrumented Systems)</li>
                                            <li> Project management</li>
                                            <li> Project engineering</li>
                                        </ul>
                                        <br/>
                                        Gaat het om een grote of complexe installatie? Geen probleem, wij hebben verschillende vaste partijen waarmee we de handen in elkaar kunnen slaan.
                                    </span>)}
                                icon={EngineeringIcon}
                                iconColor="success"
                                vertical
                            />
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Link to="/services?service=Laadpalen">
                            <InfoArea
                                title="Laadpalen"
                                description={(
                                    <span>Het einde van de klassieke verbrandingsmotoren is in zicht. Maar is uw installatie wel klaar voor de komst van de plug-in hybride of elektrische wagen?
                                        <br/>
                                        Onze specialist laadpalen kijkt samen met u naar de mogelijkheden van uw installatie en kiest voor u de meest optimale laadpaal uit voor uw wagen(s).
                                        <br/>
                                        Wist je dat je zelfs verschillende laadpalen op een uniek ontworpen Energie Management Systeem kunt aansluiten? Zo kunnen we ook voor bedrijven het verschil maken.
                                    </span>)}
                                icon={ElectricCarIcon}
                                iconColor="danger"
                                vertical
                            />
                        </Link>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}

// @ts-ignore
import kast_1 from "assets/img/projects/kast_1/kast_1.jpg";
// @ts-ignore
import kast_2 from "assets/img/projects/kast_1/kast_2.jpg";
// @ts-ignore
import kast_3 from "assets/img/projects/kast_1/kast_3.jpg";
// @ts-ignore
import kast_4 from "assets/img/projects/kast_1/kast_4.jpg";

function getImages() {
    return [
        {
            image: kast_1,
            width: "1500",
            height: "2000",
        },
        {
            image: kast_2,
            width: "2000",
            height: "1500",
        },
        {
            image: kast_3,
            width: "2000",
            height: "1500",
        },
        {
            image: kast_4,
            width: "1500",
            height: "2000",
        },

    ];
}

const kast1Project =  {
    title: "Kast",
    images: getImages(),
    thumbnails: [kast_1, kast_2, kast_3, kast_4],
}


export default kast1Project;

import React, {useRef} from "react";

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import {CustomGallery, DefaultLayout} from 'react-photoswipe-gallery'
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

const PhotoGallery = (props) => {
    const layoutRef = useRef()

    return (
        <>
            <CustomGallery layoutRef={layoutRef} ui={PhotoswipeUIDefault}
                           options={{
                    closeOnScroll: false,
                        ...props
            }}>
                {props.children}
            </CustomGallery>
            <DefaultLayout
                shareButton={false}
                closeButtonCaption="Sluit"
                toggleFullscreenButtonCaption="Volledig scherm aan/uit"
                zoomButtonCaption="Zoom in/uit"
                prevButtonCaption="Vorige"
                nextButtonCaption="Volgende"
                ref={layoutRef}
            />
            </>
    );
}

export default PhotoGallery;

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";

// @material-ui/icons
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Parallax from 'components/Parallax/Parallax';

import styles from "assets/jss/material-kit-react/views/jobsPage";

// Sections for this page
import ContactSection from "components/ContactSection/ContactSection";
import {blackColor} from "assets/jss/material-kit-react";
import {track} from "util/useTracking";

// @ts-ignore
import achtergrond from "assets/img/achtergrond.jpg";
import IndustrialElectrotechnicianSection from "./Sections/IndustrialElectrotechnicianSection";

// @ts-ignore
const useStyles = makeStyles(styles);


function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function JobsPage(props) {

    const classes = useStyles();
    const {...rest} = props;

    track("Jobs Page");

    return (
        <div>
            <Header
                fixed
                {...rest}
            />
            <Parallax filter className={classes.parallax} image={achtergrond}>
            </Parallax>
            <div className={classNames(classes.main)}>
                <div className={classes.container}>
                        <IndustrialElectrotechnicianSection />
                </div>
                <ContactSection/>
            </div>
            <Footer color={blackColor} whiteFont/>
        </div>
    );
}

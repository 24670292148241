import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";


// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/sections/closetSectionStyle";
// @ts-ignore
import image from "assets/img/services/closet.jpg";
import PhotoGallery from "components/PhotoGallery/PhotoGallery";


import {Item} from 'react-photoswipe-gallery'


const useStyles = makeStyles(styles);

const ClosetServiceSection = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();

    function getTextItem() {
        return <GridItem xs={12} sm={12} md={7} className={classes.textGrid}>

            <p className={classes.description}>
                <span className={"left"}>
                    Voor elke opdracht op het vlak van industriële of residentiële bordenbouw bieden we een gepaste oplossing. Hierbij streven we altijd naar een optimaal evenwicht tussen prijs, kwaliteit, praktische haalbaarheid en service.
                                    <br/>
                                    <br/>
                                    Onze borden worden gebouwd door techniekers die ervaring hebben in het veld, waardoor we graag meedenken met u als klant en steeds met een praktische blik kijken naar de voorgestelde plannen. Zijn er nog geen plannen? Dan helpen wij u ook daarmee graag verder.
                                    <br/>
                                    <br/>
                                    Door deze aanpak blinken we uit in het maatwerk van onder andere:
                                    <ul className={"dashed"}>
                                        <li> Stuurborden (PLC, relais, …)</li>
                                        <li> Vermogenborden</li>
                                        <li> Instrumentatie- en meetborden</li>
                                        <li> Klemmenkasten</li>
                                        <li> Klassieke (residentiële) verdeelborden</li>
                                    </ul>
                                </span>
            </p>
        </GridItem>;
    }

    function getImageItem() {
        return <GridItem xs={12} sm={12} md={5}>
            <PhotoGallery shareButton={false} closeButtonCaption="Sluit"
                          toggleFullscreenButtonCaption="Volledig scherm aan/uit" zoomButtonCaption="Zoom in/uit">
                <Item
                    original={image}
                    thumbnail={image}
                    width="1440"
                    height="1920"
                    title="Bordenbouw informatie"
                >
                    {({ref, open}) => (
                        <img onClick={open} src={image} className={classes.imgRaised + " " + classes.imgFluid}/>
                    )}
                </Item>

            </PhotoGallery>
        </GridItem>;
    }

    return (
        <div ref={ref} className={classes.section}>
            <h2 className={classes.title}>Bordenbouw</h2>
            <GridContainer className={classes.grid}>
                {getImageItem()}
                {getTextItem()}
            </GridContainer>
        </div>
    );
});

export default ClosetServiceSection;

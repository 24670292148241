import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Parallax from 'components/Parallax/Parallax';

import styles from "assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import ProductSection from './Sections/ProductSection';
import ContactSection from "components/ContactSection/ContactSection";
import {track} from "util/useTracking";
// @ts-ignore
import achtergrond from "assets/img/boot.jpg";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    track('Home Page');

    return (
        <div>
            <Header
                fixed
                {...rest}
            />
            <Parallax filter image={achtergrond}>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ProductSection/>
                </div>
                <ContactSection roundedCorners/>
            </div>
            <Footer/>
        </div>
    );
}

// @ts-ignore
import casalingo_1 from "assets/img/projects/casalingo/casalingo_1.jpg";
// @ts-ignore
import casalingo_2 from "assets/img/projects/casalingo/casalingo_2.jpg";
// @ts-ignore
import casalingo_3 from "assets/img/projects/casalingo/casalingo_3.jpg";
// @ts-ignore
import casalingo_4 from "assets/img/projects/casalingo/casalingo_4.jpg";
// @ts-ignore
import casalingo_5 from "assets/img/projects/casalingo/casalingo_5.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: casalingo_1,
            width: "1500",
            height: "2000",
        },
        {
            image: casalingo_2,
            width: "1500",
            height: "2000",
        },
        {
            image: casalingo_3,
            width: "2000",
            height: "1500",
        },
        {
            image: casalingo_4,
            width: "2000",
            height: "1500",
        },
        {
            image: casalingo_5,
            width: "2000",
            height: "1500"
        },

    ];
}

const casalingoProject =  {
    title: "Lange Kast",
    images: getImages(),
    thumbnails: [casalingo_1, casalingo_2, casalingo_3, casalingo_4, casalingo_5],
}


export default casalingoProject;

/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// material-ui core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/components/contactSectionStyle";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function ContactSection(props) {
    const classes = useStyles();
    return (
        <div className={`${classes.container}  ${props.roundedCorners ? classes.roundedCorners : ''}`}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.content}>
                        <h3 className={classes.h3}>Adres</h3>
                        <p className={classes.p}>Van Hoppe BV<br/>
                            Hoge Mauw 1300<br/>
                            2370 Arendonk</p>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.content}>
                        <Link to="/contact" className={classes.a}><h3 className={classes.h3}>Contact</h3></Link>
                        <p className={classes.p}><a className={classes.a}
                                                    href="mailto:bart@vanhoppebv.be?subject=Vraag via website">bart@vanhoppebv.be</a><br/>
                            <br/>
                            Bart: <a className={classes.a}
                                     href="tel:0493662217">0493/66.22.17</a><br/>
                            Geert: <a className={classes.a}
                                      href="tel:0476767829">0476/76.78.29</a>
                        </p>
                    </div>
                </GridItem>
            </GridContainer>

        </div>
    );
}

ContactSection.propTypes = {
    roundedCorners: PropTypes.bool
};

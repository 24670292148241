import {cardTitle, darkBlueColor, title} from "../../../material-kit-react";
import imagesStyle from "../../../material-kit-react/imagesStyles.js";

const contactSectionStyle = {
  section: {
    textAlign: "center",
    color: darkBlueColor,
    paddingLeft: "25px",
    flex: "1"
  },
  description: {
    fontSize: "16pt",
    textAlign: "left"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  justifyCenter: {
    justifyContent: "center !important",
    textAlign: "center !important",
    marginTop: "20pt",
    display: 'flex'
  },
  margin5: {
    margin: "5px"
  },
  p: {
    fontSize: "14pt",
    lineHeight: "2em",
    fontWeight: "500"
  },
  error: {
    backgroundColor: "red",
    borderRadius: "10px",
    paddingTop: "10pt",
    paddingBottom: "10pt",
    textAlign: "center",
    color: "white"
  },
  success: {
    backgroundColor: "green",
    borderRadius: "10px",
    paddingTop: "10pt",
    paddingBottom: "10pt",
    textAlign: "center",
    color: "white"
  },
};

export default contactSectionStyle;

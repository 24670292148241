import React, {useRef} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Parallax from 'components/Parallax/Parallax';

import styles from "assets/jss/material-kit-react/views/aboutPage";

// Sections for this page
import ContactSection from "components/ContactSection/ContactSection";
import HistorySection from "views/AboutPage/Sections/HistorySection";
import SideBar from "components/SideBar/SideBar";
import {blackColor} from "assets/jss/material-kit-react";
import {track} from "util/useTracking";
// @ts-ignore
import achtergrond from "assets/img/boot.jpg";
import TeamSectionWithoutPictures from "views/AboutPage/Sections/TeamSectionWithoutPictures";


// @ts-ignore
const useStyles = makeStyles(styles);

export default function AboutPage(props) {
    const classes = useStyles();
    const {...rest} = props;
    const historyRef = useRef(null);
    const teamRef = useRef(null);

    function scrollTo(ref: React.MutableRefObject<any>, e ) {
        e.currentTarget.blur();
        ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
    }

    const links = [
        {title: "Geschiedenis", onClick: e => scrollTo(historyRef, e)},
        {title: "Management", onClick: e => scrollTo(teamRef, e)},
    ]

    track("About Page");
    
    const getAboutWithoutTeam = () => {
        return (<SideBar links={links}>
            <HistorySection ref={historyRef}/>
            <TeamSectionWithoutPictures ref={teamRef}/>
        </SideBar>);
    }

    return (
        <div>
            <Header
                fixed
                {...rest}
            />
            <Parallax filter className={classes.parallax} image={achtergrond}>
            </Parallax>
            <div className={classNames(classes.main)}>
                <div className={classes.container}>
                    {getAboutWithoutTeam()}
                </div>
                <ContactSection />
            </div>
            <Footer color={blackColor} whiteFont/>
        </div>
    );
}

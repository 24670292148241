/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import styles from "assets/jss/material-kit-react/components/footerStyle";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const {whiteFont, color} = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });

    const bgColor = color ?? "#e5e5e5";

    return (
        <footer className={footerClasses} style={{backgroundColor: bgColor}}>
            <div className={classes.container} style={{backgroundColor: bgColor}}>
                <div className={classes.right}>
                    &copy; {new Date().getUTCFullYear()}, Van Hoppe Industrial Solutions. <br/>
                    Ontwikkeld door: <a href={"https://www.vangils-it.be"} target="_blank"
                                                             className={classes.footerLink}> Van Gils IT Solutions</a>.
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool,
    color: PropTypes.string
};

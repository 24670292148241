import {title} from "../../../material-kit-react";

const productStyle = {
  section: {

    "@media (min-width: 768px)": {
      padding: "30px 0",
    },
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  },
};

export default productStyle;

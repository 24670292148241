/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/components/mobileHeaderLinksStyle";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function MobileHeaderLinks(props) {
    const classes = useStyles();

    const isOpened = (page: string) => {
        return window.location.pathname === page;
    }

    function activeClass(page: string) {
        return isOpened(page) ? classes.navItemActive : '';
    }
    return (
        <List className={classes.list} >
            <ListItem className={classes.listItem}>
                <Link to="/" className={`${classes.navItem}  ${activeClass('/')}`}>
                    Home
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/over" className={`${classes.navItem}  ${activeClass('/over')}`}>
                    Over Ons
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/services" className={`${classes.navItem}  ${activeClass('/services')}`}>
                    Services
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/projecten" className={`${classes.navItem}  ${activeClass('/projecten')}`}>
                    Projecten
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/contact" className={`${classes.navItem}  ${activeClass('/contact')}`}>
                    Contact
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/jobs" className={`${classes.navItem}  ${activeClass('/jobs')}`}>
                    Jobs
                </Link>
            </ListItem>
        </List>
    );
}

import {cardTitle, darkBlueColor, title} from "../../../material-kit-react";
import imagesStyle from "../../../material-kit-react/imagesStyles.js";

const projectSectionStyle = {
    section: {
        textAlign: "center",
        color: darkBlueColor,
        marginBottom: "30pt"
    },
    textGrid: {
        "@media (max-width: 767px)": {
            marginTop: "25pt",
        },
    },
    description: {
        fontSize: "16pt",
        textAlign: "left",
        whiteSpace: 'pre-line !important'
    },
    title: {
        ...title,
        marginBottom: "2rem",
        marginTop: "40px",
        minHeight: "32px",
        textDecoration: "none"
    },
    ...imagesStyle,
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle,
    smallTitle: {
        color: "#6c757d"
    },
    gridContainer: {
        width: "100%"
    },
    margin5: {
        margin: "5px"
    },
    image: {
        width: "auto",
        maxHeight: "350pt",
    },
    imageDiv: {
        position: "relative",
        textAlign: "center",
        height: "100%"
    },
    imageTextContainer: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        backgroundColor: "white",
        width: "100%",
        height: "35px",
      opacity: "88%"
    },
    imageText: {
        //fontSize: "1.3vw",
      fontSize: "17pt",
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
};

export default projectSectionStyle;

import React from "react";
import StickyBox from "react-sticky-box";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/components/sideBarStyle";
import {Divider} from "@mui/material";

// @ts-ignore
const useStyles = makeStyles(styles);

export default function SideBar(props) {
    const classes = useStyles();

    function getOffset() {
        if(getWindowDimensions().width >= 1200) {
            return 90;
        } else {
            return 50;
        }
    }

    return (
        <div className={classes.container}>
            <StickyBox className={classes.sticky} offsetTop={getOffset()}>
                <div className={classes.left}>
                    <h2 className={classes.h2}>Inhoud</h2>
                    <Divider className={classes.divider} sx={{borderBottomWidth: 3}}/>
                    {props.links && props.links.map(link => {
                        return (<a key={link.title} className={classes.a} onClick={link.onClick}><h3 className={classes.link}>{link.title}</h3></a>);
                    })}
                </div>
            </StickyBox>
            <div className={classes.right}>
                {props.children}
            </div>
        </div>
    );
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

// @ts-ignore
import dcm_1 from "assets/img/projects/dcm/dcm_1.jpg";
// @ts-ignore
import dcm_2 from "assets/img/projects/dcm/dcm_2.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: dcm_1,
            width: "1125",
            height: "2000",
        },
        {
            image: dcm_2,
            width: "2000",
            height: "1125",
        },

    ];
}

const dcmProject =  {
    title: "DCM",
    images: getImages(),
    thumbnails: [dcm_1, dcm_2,],
}


export default dcmProject;

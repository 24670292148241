import React from "react";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/views/sections/mapsSectionStyle";

const useStyles = makeStyles(styles);



const MapSection = (props) =>  {
  const classes = useStyles();
    const defaultProps = {
        center: {
            lat: 51.3006445,
            lng: 5.0954332
        },
        zoom: 18
    };

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: 51.3006445,
        lng: 5.0954332
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '400pt', width: '100%' }}>
            <LoadScript
                googleMapsApiKey="AIzaSyB1JKR-772bRB3UNzCkYjgBZ8eJVGfxvoI"
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                >
                    <Marker
                        icon={"/marker.png"}
                        position={{
                            lat: 51.300599481252085,
                            lng: 5.096042103299524
                        }}
                    />
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapSection;

// @ts-ignore
import lange_1 from "assets/img/projects/lange_kast/lange_1.jpg";
// @ts-ignore
import lange_2 from "assets/img/projects/lange_kast/lange_2.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: lange_1,
            width: "1500",
            height: "2000",
        },
        {
            image: lange_2,
            width: "1500",
            height: "2000",
        },

    ];
}

const paSolutionsProject =  {
    title: "Lange Kast",
    images: getImages(),
    thumbnails: [lange_1, lange_2,],
}


export default paSolutionsProject;

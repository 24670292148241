// @ts-ignore
import voordien_1 from "assets/img/projects/voor_en_na/voordien_1.jpg";
// @ts-ignore
import voordien_2 from "assets/img/projects/voor_en_na/voordien_2.jpg";
// @ts-ignore
import voordien_3 from "assets/img/projects/voor_en_na/voordien_3.jpg";
// @ts-ignore
import nadien_1 from "assets/img/projects/voor_en_na/nadien_1.jpg";
// @ts-ignore
import nadien_2 from "assets/img/projects/voor_en_na/nadien_2.jpg";
// @ts-ignore
import nadien_3 from "assets/img/projects/voor_en_na/nadien_3.jpg";
// @ts-ignore
import nadien_4 from "assets/img/projects/voor_en_na/nadien_4.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: nadien_1,
            width: "1500",
            height: "2000",
            title: "Nadien"
        },
        {
            image: nadien_2,
            width: "1500",
            height: "2000",
            title: "Nadien"
        },
        {
            image: nadien_3,
            width: "1468",
            height: "2000",
            title: "Nadien"
        },
        {
            image: nadien_4,
            width: "1500",
            height: "2000",
            title: "Nadien"
        },
        {
            image: voordien_1,
            width: "1500",
            height: "2000",
            title: "Voordien"
        },
        {
            image: voordien_2,
            width: "1500",
            height: "2000",
            title: "Voordien"
        },
        {
            image: voordien_3,
            width: "1500",
            height: "2000",
            title: "Voordien"
        },

    ];
}

const voorEnNaProject =  {
    title: "Kasten",
    images: getImages(),
    thumbnails: [nadien_3, nadien_2, nadien_4, nadien_1, voordien_1, voordien_2, voordien_3],
}


export default voorEnNaProject;

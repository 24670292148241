import {darkBlueColor, primaryColor} from "assets/jss/material-kit-react.js";

const sideBarStyle = {
        sticky: {
            "@media (max-width: 768px)": {
                display: "none"
            },
            flex: "0.28",
            marginRight: "15pt"
        },
        left: {
            float: "left!important",
            display: "block",
            justifyContent: "top",
            alignItems: "top",
            flex: "0.33",
            color: darkBlueColor,
            paddingTop: "20pt",
            width: "100%"
        },
        right: {
            margin: "0",
            float: "right!important",
            display: "block",
            flex: "1",
            justifyContent: "center",
            alignItems: "center",
        },
        container: {
            flex: "1",
            alignItems: "flex-start",
            justifyContent: "top",
            display: "flex",
            flexWrap: "nowrap",
            margin: "0",
        },
        h2: {
            fontWeight: "600",
            fontSize: "25pt"
        },
        divider: {
            background: darkBlueColor
        },
        link: {
            fontSize: "17pt",
            fontWeight: "400",
            lineHeight: "1em",
            cursor: "pointer",
            "@media (hover: hover)": {
                "&:hover": {
                    color: primaryColor
                }
            },
        },
        a: {
            color: darkBlueColor,
            "&:hover": {
                color: darkBlueColor
            }

        }
    }
;

export default sideBarStyle;

import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/sections/chargingStationSectionStyle";
// @ts-ignore
import image from "assets/img/services/chargingStation.png";

import PhotoGallery from "components/PhotoGallery/PhotoGallery";
import {Item} from "react-photoswipe-gallery";

const useStyles = makeStyles(styles);

const ChargingStationServiceSection = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    function isMobileDevice() {
        return  getWindowDimensions().width < 768;
    }

    function getImageItem() {
        return <GridItem xs={12} sm={12} md={5}>
            <PhotoGallery shareButton={false} closeButtonCaption="Sluit" toggleFullscreenButtonCaption="Volledig scherm aan/uit" zoomButtonCaption="Zoom in/uit">
                <Item
                    original={image}
                    thumbnail={image}
                    width="1000"
                    height="665"
                    title="Laadpaal informatie"
                >
                    {({ ref, open }) => (
                        <img onClick={open} src={image}  className={classes.imgRaised + " " + classes.imgFluid}/>
                    )}
                </Item>

            </PhotoGallery>
        </GridItem>;
    }

    function getTextItem() {
        return <GridItem xs={12} sm={12} md={7} className={classes.textGrid}>

            <p className={classes.description}>
                <span>Het einde van de klassieke verbrandingsmotoren is in zicht. Maar is uw installatie wel klaar voor de komst van de plug-in hybride of elektrische wagen?
                                                        <br/>
                                                        Onze specialist laadpalen kijkt samen met u naar de mogelijkheden van uw installatie en kiest voor u de meest optimale laadpaal uit voor uw wagen(s).
                                                        <br/>
                                                        Wist je dat je zelfs verschillende laadpalen op een uniek ontworpen Energie Management Systeem kunt aansluiten? Zo kunnen we ook voor bedrijven het verschil maken.
                                                    </span>
            </p>
        </GridItem>;
    }

    return (
        <div ref={ref} className={classes.section}>
            <h2 className={classes.title}>Laadpaal</h2>
            <GridContainer className={classes.grid}>
                {isMobileDevice() ? getImageItem()  : getTextItem()}
                {isMobileDevice() ? getTextItem()  : getImageItem()}
            </GridContainer>
        </div>
    );
});

export default ChargingStationServiceSection;

import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Card from "../../../components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/sections/teamStyle";

// @ts-ignore
import image from "assets/img/faces/avatar.jpg";
// @ts-ignore
import erwin from "assets/img/faces/erwin.png";

const useStyles = makeStyles(styles);

const TeamSectionWithoutPictures = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.section}>
      <h2 className={classes.title}>Management</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <h4 className={classes.cardTitle}>
                Bart Van Hoppe
                <br />
                <small className={classes.smallTitle}>Bestuurder</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <h4 className={classes.cardTitle}>
                Geert Van Hoppe
                <br />
                <small className={classes.smallTitle}>Bestuurder</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <h4 className={classes.cardTitle}>
                Erwin Van Hoppe
                <br />
                <small className={classes.smallTitle}>Bestuurder</small>
              </h4>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
});

export default TeamSectionWithoutPictures;

import {cardTitle, darkBlueColor, scrollMarginTop, title} from "../../../material-kit-react";
import imagesStyle from "../../../material-kit-react/imagesStyles.js";

const engineeringSectionStyle = {
  section: {
    ...scrollMarginTop,
    textAlign: "center",
    color: darkBlueColor,

    "@media (max-width: 767px)": {
      paddingLeft: "5px",
      paddingRight: "5px"
    },
    "@media (min-width: 768px)": {
      paddingLeft: "25px",
    },
    flex: "1"
  },

  grid: {
    width: "auto",
    marginRight: "0px",
    marginLeft: "0px"
  },
  textGrid: {
    "@media (max-width: 576px)": {
      marginTop: "25pt",
    },
  },
  description: {
    fontSize: "16pt",
    textAlign: "left"
  },
  title: {
    ...title,
    marginBottom: "2rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  }
};

export default engineeringSectionStyle;

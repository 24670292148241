import {
  dangerColor,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  title,
  warningColor
} from "assets/jss/material-kit-react.js";

const infoStyle = {
  infoArea: {
    margin: "0 auto",
    marginTop: "10pt",
    marginBottom: "10pt",
    paddingTop: "2pt",
    paddingBottom: "5pt",
    paddingLeft: "1em",
    paddingRight: "1em",
    "&:hover": {
      boxShadow:
          "0 5px 25px 0px rgba(0, 0, 0, 0.42)",
    },
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px",
  },
  primary: {
    color: primaryColor
  },
  warning: {
    color: warningColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  icon: {
    width: "36px",
    height: "36px",
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden"
  },
  title : {
    ...title,
    fontSize: "22pt"
  },
  description: {
    color: grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14pt",
    textAlign: "left"
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  }
};

export default infoStyle;

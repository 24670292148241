import mixpanel from 'mixpanel-browser';

export const track = (what: string, properties?: any) => {

    if(!!properties) {
        mixpanel.track(what, properties);
    } else {
        mixpanel.track(what)
    }

}

import {blackColor, whiteColor} from "assets/jss/material-kit-react.js";

const contactSectionStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    flex: "1"
  },
  right: {
    margin: "0",
    float: "right!important",
    display: "block",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: whiteColor,
    flex: "1"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: whiteColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  h3 : {
    fontWeight: "600",
    fontSize: "24pt",
  },
  p : {
    fontWeight: "500",
    fontSize: "13pt",
    lineHeight: "1.7em"
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    minHeight: "150px",
    alignItems: "center",
    backgroundColor: blackColor,
    paddingTop: "15pt",
    paddingBottom: "25pt",
  },
  roundedCorners: {
    "@media (min-width: 576px)": {
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  footerLink: {
    color: "inherit",
    textDecoration: "none",
  }
};
export default contactSectionStyle;

import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";


// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/sections/jobSectionStyle";
// @ts-ignore
import image from "assets/img/services/closet.jpg";
import PhotoGallery from "components/PhotoGallery/PhotoGallery";


import {Item} from 'react-photoswipe-gallery'


const useStyles = makeStyles(styles);

const IndustrialElectrotechnicianSection = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();

    function getTextItem() {
        return <p className={classes.description}>
            Om onze afdeling installatiewerken en bordenbouw te versterken zijn wij op zoek naar een industriële
            elektrotechnieker.
            <br/>
            <br/>
            <b><i>Jouw gevarieerd takenpakket:</i></b>
            <br/>
            <br/>
            <ul className={classes.list}>
                <li>In ons atelier werk je aan verdeel-, stuur- en vermogenborden.</li>
                <li>Bij onze klanten werk je aan alle soorten elektrische- en geautomatiseerde installaties.</li>
                <li>Zowel het implementeren van nieuwe elektrische installaties als het onderhouden ervan,
                    heeft geen geheimen voor jou.
                </li>
                <li>Uitbreiden en aanpassen van machines, in ons atelier of ter plaatse bij de klant.</li>
                <li>Je neemt deel aan commissioning- en validatietesten.</li>
                <li>Fouten opsporen en herstellen zijn voor jou een uitdaging.</li>
                <li>Het plaatsen van laadpalen met bijhorende aanpassing van de elektrische installatie
                    neem je voor jouw rekening.
                </li>
            </ul>
            <br/>
            <br/>
            <b><i>Profiel:</i></b>
            <br/>
            <br/>
            <ul className={classes.list}>
                <li>Je bent in het bezit van een diploma secundair of hoger technisch onderwijs in
                    elektrotechnieken/elektromechanica of evenwaardig door ervaring.
                </li>
                <li>Je hebt reeds ervaring op gebied van elektrische- en automatiseringswerken.</li>
                <li>Kennis of ervaring in mechanische werken zijn een pluspunt.</li>
                <li>Je kan accuraat, nauwgezet en klantgericht werken.</li>
                <li>Je kan prioriteiten stellen en een duidelijke inschatting maken van de uit te voeren
                    werken.
                </li>
                <li>Je spreekt vloeiend Nederlands.</li>
                <li>Je bent flexibel en in het bezit van een rijbewijs B.</li>
                <li>Je bent in het bezit van een VCA-attest, of bent gemotiveerd deze te behalen.</li>
            </ul>
            <br/>
            <br/>
            <b><i>Wat bieden wij jou aan:</i></b>
            <br/>
            <br/>
            <ul className={classes.list}>
                <li>Een zeer gevarieerde job in dagdienst.</li>
                <li>Een groeiend, flexibel bedrijf, waarin je meesleutelt aan de bouw van de toekomst.</li>
                <li>Een marktconform salarispakket.</li>
            </ul>
        </p>;
    }

    return (
        <div ref={ref} className={classes.section}>
            <h2 className={classes.title}>Industriële elektrotechnieker</h2>
            {getTextItem()}
        </div>
    );
});

export default IndustrialElectrotechnicianSection;

import React, {useEffect, useRef} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";

// @material-ui/icons
// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Parallax from 'components/Parallax/Parallax';

import styles from "assets/jss/material-kit-react/views/servicesPage";

// Sections for this page
import ContactSection from "components/ContactSection/ContactSection";
import SideBar from "components/SideBar/SideBar";
import ClosetServiceSection from "views/ServicesPage/Sections/ClosetServiceSection";
import EngineeringServiceSection from "views/ServicesPage/Sections/EngineeringServiceSection";
import IndustrialServiceSection from "views/ServicesPage/Sections/IndustrialServiceSection";
import ChargingStationServiceSection from "views/ServicesPage/Sections/ChargingStationServiceSection";
import {blackColor} from "assets/jss/material-kit-react";
import {track} from "util/useTracking";

// @ts-ignore
import achtergrond from "assets/img/achtergrond.jpg";

// @ts-ignore
const useStyles = makeStyles(styles);


function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ServicesPage(props) {
    const history = useHistory();
    let query = useQuery();

    const classes = useStyles();
    const {...rest} = props;
    const closetRef = useRef(null);
    const engineeringRef = useRef(null);
    const industrialRef = useRef(null);
    const chargeStationRef = useRef(null);

    const getReference = (searchQuery: string) => {
        switch (searchQuery) {
            case "Kasten":
                return closetRef;
            case "Engineering":
                return engineeringRef;
            case "Industriele_installaties":
                return industrialRef;
            case "Laadpalen":
                return chargeStationRef;
        }
    }

    useEffect(() => {
        const service = query.get("service");
        if (!!service) {
            const reference = getReference(service);
            if (reference) {
                setTimeout(() => {
                    scrollTo(reference);
                }, 400);
            }

        }
    }, [query, closetRef, engineeringRef, industrialRef, chargeStationRef])

    function scrollTo(ref: React.MutableRefObject<any>) {
        ref.current.scrollIntoView({block: 'start', behavior: 'smooth', inline: "start"});
    }

    const links = [
        {title: "Bordenbouw", onClick: () => scrollTo(closetRef)},
        {title: "Industriële installaties", onClick: () => scrollTo(industrialRef)},
        {title: "Engineering", onClick: () => scrollTo(engineeringRef)},
        {title: "Laadpalen", onClick: () => scrollTo(chargeStationRef)},
    ]

    track("Service Page");

    return (
        <div>
            <Header
                fixed
                {...rest}
            />
            <Parallax filter className={classes.parallax} image={achtergrond}>
            </Parallax>
            <div className={classNames(classes.main)}>
                <div className={classes.container}>
                    <SideBar links={links}>
                        <ClosetServiceSection ref={closetRef}/>
                        <IndustrialServiceSection ref={industrialRef}/>
                        <EngineeringServiceSection ref={engineeringRef}/>
                        <ChargingStationServiceSection ref={chargeStationRef}/>
                    </SideBar>
                </div>
                <ContactSection/>
            </div>
            <Footer color={blackColor} whiteFont/>
        </div>
    );
}

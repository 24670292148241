import {defaultFont, primaryColor} from "assets/jss/material-kit-react.js";

import tooltip from "assets/jss/material-kit-react/tooltipsStyle.js";

const mobileHeaderLinksStyle = {
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    color: "inherit",
    width: "auto",
    margin: "0",
    padding: "0",
    paddingBottom: "10pt",
    justifyContent: "center"
  },
  listItemText: {
    padding: "0 !important"
  },
  navItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    fontSize: "16pt"
  },
  navItemActive: {
    color: primaryColor
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px"
  }
};

export default mobileHeaderLinksStyle;

import React, {useRef, useState} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import {TailSpin} from 'react-loader-spinner';
import emailjs from '@emailjs/browser';

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/views/sections/contactFormStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import {track} from "util/useTracking";

const useStyles = makeStyles(styles);

const ContactForm = (props) => {
    const classes = useStyles();
    const form = useRef(null);
    const recaptchaRef = React.createRef();

    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const initialState = {
        name: "",
        email: "",
        phone: "",
        message: ""
    };
    const [contactMessage, setContactMessage] = useState(initialState);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onChangeRecaptcha = value => {
        if (value && value.length > 0) {
            setError(undefined);
        }
    }
    const isRequiredFilledIn = contactMessage.name.length > 0 && contactMessage.email.length > 0 && contactMessage.message.length > 0;

    const onChangeField = () => {
        if (isRequiredFilledIn) {
            setError(undefined);
        }
    }

    const handleSubmit = event => {
        event.preventDefault()
        // @ts-ignore
        const recaptchaValue = recaptchaRef.current.getValue();

        if (recaptchaValue && recaptchaValue.length > 0) {
            setSubmitted(true);
            if (!isRequiredFilledIn) {
                setError("Gelieve alle verplichte velden (aangeduid met *) in te vullen.");
            } else {
                setLoading(true);
                setError(undefined)
                // @ts-ignore
                recaptchaRef.current.reset();
                setSubmitted(false);
                emailjs.send('service_0dw4wja', 'template_tqpds4y', contactMessage, 'gtGYoSj-94gVSo_ta')
                    .then(res => {
                        setLoading(false);
                        if (res.status === 200) {
                            setFormSubmitSuccessful(true);
                            setTimeout(() => setFormSubmitSuccessful(false), 5000);
                            setContactMessage(initialState);
                            form.current.reset();
                        } else {
                            setError("Er ging iets mis bij het versturen. Probeer later opnieuw of stuur een email naar <a href=mailto:bart@vanhoppebv.be />")
                        }
                    })
                    // Handle errors here however you like
                    .catch(err => {
                        setError("Er ging iets mis bij het versturen. Probeer later opnieuw of stuur een email naar bart@vanhoppebv.be");
                        setLoading(false);
                    });
                track("ContactForm Submitted", contactMessage);

                setContactMessage({...initialState});
            }
        } else {
            setError("ReCAPTCHA niet correct.");
        }
    }

    return (
        <div className={classes.section}>
            {loading && <TailSpin color="#00BFFF" height={80} width={80} wrapperStyle={{justifyContent: "center"}}/>}
            <form ref={form} onSubmit={handleSubmit}>
                <GridContainer justifyContent="center">
                    {error && error.length > 0 && <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.error}>{error}</h5>
                    </GridItem>}
                    {formSubmitSuccessful && <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.success}>Bedankt voor ons te contacteren. U hoort zo snel
                            mogelijk van ons.</h5>
                    </GridItem>}
                    <GridItem>
                        <CustomInput
                            labelText="Naam*"
                            id="name"
                            name="user[name]"
                            disabled={loading}
                            defaultValue={contactMessage.name}
                            error={submitted && contactMessage.name === ''}
                            onChange={e => {
                                setContactMessage({...contactMessage, "name": e.target.value});
                                onChangeField();
                            }}
                            required
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <CustomInput
                            labelText="Email*"
                            id="email"
                            name="user[email]"
                            disabled={loading}
                            defaultValue={contactMessage.email}
                            required
                            error={submitted && !emailRegex.test(contactMessage.email)}
                            onChange={e => {
                                setContactMessage({...contactMessage, "email": e.target.value});
                                onChangeField();
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <CustomInput
                            labelText="Telefoonnummer"
                            id="name"
                            name="user[phone]"
                            disabled={loading}
                            defaultValue={contactMessage.phone}
                            onChange={e => {
                                setContactMessage({...contactMessage, "phone": e.target.value});
                                onChangeField();
                            }}
                            required
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <CustomInput
                            labelText="Bericht*"
                            id="message"
                            name="user[message]"
                            disabled={loading}
                            defaultValue={contactMessage.message}
                            required
                            error={submitted && contactMessage.message === ''}
                            onChange={e => {
                                setContactMessage({...contactMessage, "message": e.target.value});
                                onChangeField();
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.textArea
                            }}
                            inputProps={{
                                multiline: true,
                                rows: 5
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.justifyCenter}>
                        {!loading && <div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdHOwYfAAAAABptHUtvZ_PBmOhnGtt-594rcXGW"
                                style={{justifyContent: "center", textAlign: "center", display: "flex", maxWidth: "100px"}}
                                onChange={onChangeRecaptcha}
                            />
                        </div>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} className={classes.justifyCenter}>
                        <Button type="submit" color="primary" disabled={loading}>Verzend bericht</Button>
                    </GridItem>
                </GridContainer>
            </form>
        </div>
    );
};

export default ContactForm;

import {cardTitle, darkBlueColor, scrollMarginTop, title} from "../../../material-kit-react";
import imagesStyle from "../../../material-kit-react/imagesStyles.js";

const closetSectionStyle = {
  section: {
    textAlign: "center",
    color: darkBlueColor,
    paddingLeft: "25px",
    marginBottom: "50pt",
    paddingTop: "5pt"
  },

  grid: {
    width: "auto",
    marginRight: "0px",
    marginLeft: "0px"
  },
  textGrid: {
    "@media (max-width: 576px)": {
      marginTop: "25pt",
    },
  },
  description: {
    fontSize: "16pt",
    justifyContent: "center"
  },
  title: {
    ...title,
    marginBottom: "2rem",
    marginTop: "0",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  margin5: {
    margin: "5px"
  },
  list: {
    display: "inline-block",
    textAlign: "left",
    width: "800pt"
  }
};

export default closetSectionStyle;

// @ts-ignore
import random_1 from "assets/img/projects/random/random_1.jpg";
// @ts-ignore
import random_2 from "assets/img/projects/random/random_2.jpg";
// @ts-ignore
import random_3 from "assets/img/projects/random/random_3.jpg";
// @ts-ignore
import random_4 from "assets/img/projects/random/random_4.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: random_1,
            width: "1500",
            height: "2000",
        },
        {
            image: random_2,
            width: "1500",
            height: "2000",
        },
        {
            image: random_3,
            width: "1500",
            height: "2000",
        },
        {
            image: random_4,
            width: "1500",
            height: "2000",
        },

    ];
}

const randomProject =  {
    title: "Verzameling",
    images: getImages(),
    thumbnails: [random_1, random_2, random_3, random_4],
}


export default randomProject;

// @ts-ignore
import gfs_1 from "assets/img/projects/gfs/gfs_1.jpg";
// @ts-ignore
import gfs_2 from "assets/img/projects/gfs/gfs_2.jpg";
// @ts-ignore
import gfs_3 from "assets/img/projects/gfs/gfs_3.jpg";
// @ts-ignore
import gfs_4 from "assets/img/projects/gfs/gfs_4.jpg";

import React from "react";


function getImages() {
    return [
        {
            image: gfs_1,
            width: "2000",
            height: "1500",
        },
        {
            image: gfs_2,
            width: "2000",
            height: "1500",
        },
        {
            image: gfs_3,
            width: "1500",
            height: "2000",
        },
        {
            image: gfs_4,
            width: "1500",
            height: "2000",
        },

    ];
}

const gfsProject =  {
    title: "GFS",
    images: getImages(),
    thumbnails: [gfs_1, gfs_2, gfs_3, gfs_4],
}


export default gfsProject;

import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import styles from "assets/jss/material-kit-react/views/sections/historyStyle";

const useStyles = makeStyles(styles);

const HistorySection = forwardRef((props, ref:ForwardedRef<HTMLDivElement>) =>  {
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.section}>
      <h2 className={classes.title}>Geschiedenis</h2>
     <p className={classes.description}>
         <strong>1996:</strong> Van Hoppe werd in 1996 opgericht als “elektriciteitswerken Erwin Van Hoppe”, nadat Erwin een paar jaar elektrische installaties op de residentiele markt in bijberoep deed. Kwaliteit en service stonden toen al hoog in het vaandel.<br/><br/>
         <strong>2006:</strong> 10 jaar later, in 2006 werd het eerste personeel aangenomen, welke nu nog steeds in dienst zijn. De activiteiten konden hierdoor worden uitgebreid.<br/><br/>
         <strong>2008:</strong> In 2008 studeerde Erwin zijn oudste zoon Bart af in de richting elektrotechnieken. Bart kwam na 2 jaar opleiding in de zaak, Van hoppe BV is geboren.<br/><br/>
         <strong>2014:</strong> In 2014 werden via fusie 2 zusterfirma's opgericht. Deze zusterbedrijven zijn tot op de dag van vandaag nog steeds actief in de residentiele markt.<br/><br/>
         <strong>2017:</strong> In 2017 zijn we begonnen met de bouw van ons pand op het industrieterrein van de Hoge Mauw. Hierdoor kan er fors uitgebreid worden.<br/>
         Op de nieuwe locatie is er de nodige stockageruimte, burelen en werkplaats voor onze bordenbouw.<br/><br/>
         <strong>2021:</strong> In 2017 studeert Geert af als industrieel ingenieur elektromechanica – automatisering. Geert heeft de eerste jaren bij externe firma’s gewerkt, maar uiteindelijk heeft hij in 2021 de stap genomen om bij in het familiebedrijf te stappen. De activiteiten van Van Hoppe krijgen een industrieel karakter.<br/><br/>

     </p>
    </div>
  );
});

export default HistorySection;

import React, {ForwardedRef, forwardRef} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/sections/engineeringSectionStyle";
// @ts-ignore
import image from "assets/img/services/engineering.jpg";

import PhotoGallery from "components/PhotoGallery/PhotoGallery";
import {Item} from "react-photoswipe-gallery";

const useStyles = makeStyles(styles);

const EngineeringServiceSection = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();


    function getTextItem() {
        return <GridItem xs={12} sm={12} md={7} className={classes.textGrid}>

            <p className={classes.description}>
                <span>Naast elektrische installatiewerken heeft Van Hoppe BV ook de capaciteiten om nieuwe of te wijzigen installaties te ontwerpen en projecten van A tot Z op te volgen. Hierbij houden we altijd de geldende regelgeving, klant specifieke standaarden en timing nauwlettend in het oog.
                                        <br/>
                                        <br/>
                                        De mogelijkheden:
                                        <ul className={"dashed"}>
                                            <li> E&I Engineering</li>
                                            <li> Functional Safety Engineering (TÜV Rheinland, #18428/ 19, Safety Instrumented Systems)</li>
                                            <li> Project management</li>
                                            <li> Project engineering</li>
                                        </ul>
                                        <br/>
                                        Gaat het om een grote of complexe installatie? Geen probleem, wij hebben verschillende vaste partijen waarmee we de handen in elkaar kunnen slaan.
                                    </span>
            </p>
        </GridItem>;
    }

    function getImageItem() {
        return <GridItem xs={12} sm={12} md={5}>
            <PhotoGallery shareButton={false} closeButtonCaption="Sluit" toggleFullscreenButtonCaption="Volledig scherm aan/uit" zoomButtonCaption="Zoom in/uit">
                <Item
                    original={image}
                    thumbnail={image}
                    width="1680"
                    height="1117"
                    title="Engineering informatie"
                >
                    {({ ref, open }) => (
                        <img onClick={open} src={image}  className={classes.imgRaised + " " + classes.imgFluid}/>
                    )}
                </Item>

            </PhotoGallery>
        </GridItem>;
    }

    return (
        <div ref={ref} className={classes.section}>
            <h2 className={classes.title}>Engineering</h2>
            <GridContainer className={classes.grid}>
                {getImageItem()}
                {getTextItem()}
            </GridContainer>
        </div>
    );
});

export default EngineeringServiceSection;

// @ts-ignore
import corso from "assets/img/projects/corso/corso.jpg";


function getImages() {
    return [
        {
            image: corso,
            width: "1500",
            height: "2000",
        },

    ];
}

const corsoProject =  {
    title: "DCM",
    images: getImages(),
    thumbnails: [corso],
}


export default corsoProject;

import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from 'history';
import {Route, Router, Switch, useLocation} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";
import mixpanel from 'mixpanel-browser';
// pages for this product
import Components from "./views/Components/Components.js";
import LandingPage from "./views/LandingPage/LandingPage";
import AboutPage from "views/AboutPage/AboutPage";
import ServicesPage from "views/ServicesPage/ServicesPage";
import ContactPage from "views/ContactPage/ContactPage";
import ProjectPage from "views/ProjectsPage/ProjectPage";
import {track} from "util/useTracking";
import JobsPage from "views/JobsPage/JobsPage";

var hist = createBrowserHistory();


function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ScrollToTop() {
    const {pathname} = useLocation();
    let query = useQuery();

    useEffect(() => {
        const service = query.get("service");
        if(!service) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }, [pathname, query]);

    return null;
}

mixpanel.init('9e1457a87d390d2747128600f624f3d5', {debug: true});

track("Visit");

ReactDOM.render(
    <Router history={hist}>
        <ScrollToTop/>
        <Switch>
            <Route path="/components" component={Components}/>
            <Route path="/over" component={AboutPage}/>
            <Route path="/services" component={ServicesPage}/>
            <Route path="/projecten" component={ProjectPage}/>
            <Route path="/contact" component={ContactPage}/>
            <Route path="/jobs" component={JobsPage}/>
            <Route path="/" component={LandingPage}/>
        </Switch>
    </Router>,
    document.getElementById("root")
);

import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import DirectionsIcon from '@mui/icons-material/Directions';
// core components
import styles from "assets/jss/material-kit-react/views/sections/contactSectionStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ContactForm from "views/ContactPage/Sections/ContactForm";

const useStyles = makeStyles(styles);

const ContactSection = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <h2 className={classes.title}>Contact</h2>

            <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                    <div className={classes.content}>
                        <ContactForm/>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.content}>
                        <p className={classes.p}>Email: <a className={classes.a}
                                                           href="mailto:bart@vanhoppebv.be?subject=Vraag via website">bart@vanhoppebv.be</a><br/>
                            Bart: <a className={classes.a}
                                    href="tel:0493662217">0493/66.22.17</a><br/>
                            Geert: <a className={classes.a}
                                     href="tel:0476767829">0476/76.78.29</a>
                        </p>
                        <br/>
                        <br/>
                        <p className={classes.p + " " + classes.link}>
                            Van Hoppe BV<br/>
                            Hoge Mauw 1300<br/>
                            2370 Arendonk<br/>
                        </p>
                        <a className={classes.a + " " + classes.directionsLink}
                           href="https://www.google.com/maps/dir/?api=1&destination=Van+Hoppe+Industrial+solutions"
                           target="_blank">
                            <div className={classes.routeDiv}>
                                    <DirectionsIcon sx={{fontSize: 30}}/>
                                    <span className={classes.routeText}>Klik hier voor routebegeleiding</span>
                            </div>
                        </a>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default ContactSection;

import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import 'react-image-lightbox/style.css';
import 'react-slideshow-image/dist/styles.css'

// @material-ui/icons
// core components
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/sections/projectSectionStyle";
// @ts-ignore
import PhotoGallery from "components/PhotoGallery/PhotoGallery";


import {Item} from 'react-photoswipe-gallery'
import {useScrollBlock} from "components/BlockScroll/useScrollBlock";


const useStyles = makeStyles(styles);

const ProjectSection = (props) => {
    const classes = useStyles();
    const [photoIndex, setPhotoIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [blockScroll, allowScroll] = useScrollBlock();

    useEffect(() => {
        if (open) {
            blockScroll();
        } else {
            allowScroll();
        }
    })

    function getImageItem() {
        return <PhotoGallery shareButton={false} closeButtonCaption="Sluit"
                          toggleFullscreenButtonCaption="Volledig scherm aan/uit" zoomButtonCaption="Zoom in/uit">
                    {props.images.map((image, index) => (
                        <Item
                            original={image.image}
                            thumbnail={props.thumbnails[index]}
                            width={image.width}
                            height={image.height}
                            key={props.title + '_' + index}
                            title={image.title ?? ''}
                        >
                            {({ref, open}) => (

                                // @ts-ignore
                                <div ref={ref} onClick={open} hidden={index !== 0} className={classes.imageDiv}>
                                        <img src={image.image} hidden={index !== 0}  className={classes.imgRaised + " " + classes.imgFluid + " " + classes.imgFull} />
                                        <div hidden={index !== 0} className={classes.imageTextContainer}>
                                            <span hidden={index !== 0} className={classes.imageText}>Klik voor meer foto's</span>
                                        </div>
                                </div>
                            )}
                        </Item>))}
            </PhotoGallery>;
    }

    return (
            <GridItem xs={12} sm={12} md={4} className={classes.section}>
                {getImageItem()}
            </GridItem>
    );

};

export default ProjectSection;

